import { useEffect, useState, useRef } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import LogitarApi from "../api/LogitarApi";
import {
  Box,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  FormControl,
  Stack,
  CircularProgress,
  Dialog, DialogActions, DialogTitle, Button,
  FormControlLabel, Checkbox, Tooltip, 
} from "@mui/material";
import { checkboxWithLabelStyle, contentBoxStyle,  exitIconStyle, exitContainerStyle } from "../styles/styles";
import CustomToolbar, { toolbarSlotProps } from "../components/CustomToolbar";
//import CustomToolbarV2 from '../components/CustomToolbarV2'
import { localeText } from "../misc/LocaleText";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"; 
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { fi } from "date-fns/locale";
import { format, subDays } from "date-fns";
import PopupScreen from "../components/PopupScreen";
import ServiceOrderEdit from "../components/ServiceOrderEdit";
import ServiceOrderEditV2 from "../components/ServiceOrderEditV2";
import NewServiceOrder from "../components/NewServiceOrder";
import { Sync, Delete, Close } from '@mui/icons-material'
import { TipButton } from '../misc/InternalFeatures'
import { useSnackbar } from 'notistack'
import LogiTarUser, { LogiTarUserType } from "../misc/User";
import { DatePick } from '../misc/DatePick'
import PersistentDataGridPro from "../components/persistent/PersistentDataGridPro";
import { useUserConfig } from "../misc/UserConfig";
import ColorInfo from "../components/ColorInfo";

const reportType = [
  {
    type: "0",
    label: "-",
  },
  {
    type: "1",
    label: "Huoltotilaukset",
  },
  {
    type: "2",
    label: "Huolto- ja katsastushistoria",
  },
];

const dialogTextStyle = {
  display: "flex", justifyContent: "space-between", my: 0
}

const filterLabels = {
  allservice: { label: "Kaikki:", className: " ", tooltip: "Koko huoltohistoria" },
  servicehistory: { label: "Huoltotapahtumat:", className: "default-grey", tooltip: "Huoltotapahtumien tilaukset" },
  oilchange: { label: "Öljynvaihto:", className: "default-blue", tooltip: "Huoltoseurannan öljynvaihto" },
  interimservice: { label: "Välihuolto:", className: "default-yellow", tooltip: "Huoltoseurannan välihuolto" },
  yearservice3: { label: "3v huolto:", className: "default-green", tooltip: "Huoltoseurannan 3v huolto" },
}

const checkersStyle = {
  display: "inherit",
  flexDirection: "row",
  maxWidth: 1000,
  flexWrap: "wrap",
  mb: 2
}

export const vehicleByNumberSorter = (v1, v2) => Number(String(v1).split(":")[0]) - Number(String(v2).split(":")[0]);

/** @type {import("@mui/x-data-grid").GridColDef[]} */
const columnsServiceOrder = [
  { field: "vehicleByNumber", headerName: "Auto", width: 110, sortComparator: (v1, v2) => vehicleByNumberSorter(v1, v2) },
  { field: "driverByName", headerName: "Tilaaja", width: 150 },
  // { field: 'beforeDate2', headerName: 'Huolto viim. pvm', width: 150},
  // { field: 'beforeKm', headerName: 'Huolto viim. km', width: 150},
  { field: "requestInfo", headerName: "Pyydetty huolto", width: 250 },
  { field: "orderDate", headerName: "Tilauspäivä", width: 100, type: "dateTime", valueFormatter: (params) => params.value ? format(new Date(params.value), "dd.MM.yyyy"): ""},
  { field: "priorityname", headerName: "Kiireellisyys", width: 120 },
  { field: "servicedByExt", headerName: "Ulkopuolinen huolto", width: 150 },
  //{ field: 'driver', headerName: 'Tilaaja', width: 150},
  { field: 'workOrderNr',headerName: 'Työnumero', width: 100},
  { field: 'connectionname',headerName: 'Toimipaikka', width: 180},   
  { field: 'status',headerName: 'Status', width: 150},
  { field: "inProgress", headerName: "Huoltopäivä", width: 100, type: "dateTime", valueFormatter: (params) => params.value ? format(new Date(params.value), "dd.MM.yyyy") : ""},
  { field: "acceptedByName", headerName: "Hyväksyjä", width: 120 },
  { field: "acceptedDate", headerName: "Hyväksymispäivä", width: 150, type: "dateTime", valueFormatter: (params) =>  params.value ? format(new Date(params.value), "dd.MM.yyyy") : ""},
];

/** @type {import("@mui/x-data-grid").GridColDef[]} */
const columnsServiceHistory = [
  { field: "vehicleByNumber", headerName: "Auto", sortComparator: (v1, v2) => vehicleByNumberSorter(v1, v2) }, 
  { field: "driverByName", headerName: "Tilaaja"},
  { field: "beforeDate", headerName: "Huoltoviim. pvm", type: "dateTime", valueFormatter: (params) => params.value ? format(new Date(params.value), "dd.MM.yyyy") : ""},
  { field: "beforeKm", headerName: "Huoltoviim. km"},
  { field: "requestInfo", headerName: "Pyydetty huolto"},
  { field: "orderDate", headerName: "Tilauspäivä", type: "dateTime", valueFormatter: (params) => params.value ? format(new Date(params.value), "dd.MM.yyyy") : ""},
  { field: "priorityname", headerName: "Kiireellisyys"},
  { field: "inProgress", headerName: "Huoltopäivä", type: "dateTime", valueFormatter: (params) => params.value ? format(new Date(params.value), "dd.MM.yyyy") : ""},
  { field: "serviceDoneKm", headerName: "Km huollossa"},
  { field: "servicedByName", headerName: "Huollon tekijä"},
  { field: "servicedByExt", headerName: 'Ulkopuolinen huoltaja'},
  { field: "externalServiceNote", headerName: "Lisätieto"},
  { field: 'workOrderNr',headerName: 'Työnumero'},
  { field: 'connectionname',headerName: 'Toimipaikka'},   
  { field: 'status',headerName: 'Status'},
  { field: "done", headerName: 'Valmis', type: "dateTime", valueFormatter: (params) => params.value ? format(new Date(params.value), "dd.MM.yyyy") : "" },
  { field: "serviceDesc", headerName: 'Suoritettu huolto'},
  { field: "acceptedByName", headerName: "Hyväksyjä"},
  { field: "acceptedDate", headerName: "Hyväksymispäivä", type: "dateTime", valueFormatter: (params) => params.value ? format(new Date(params.value), "dd.MM.yyyy") : ""},
];

export default function ServiceHistory() {
  /** @type {{config: import("../components/persistent/PersistentDataGridPro").UserConfigDataGrid}} */
  const userConfig = useUserConfig("serviceHistory");
  const userConfigOrder = useUserConfig("serviceHistoryOrder");
  const userConfigHistory = useUserConfig("serviceHistoryHistory");

  const [serviceOrder, setServiceOrder] = useState([]);
  const [serviceOrderForEdit, setServiceOrderForEdit] = useState();
  const [serviceHistory, setServiceHistory] = useState();
  const [searchTimeStamp, setSearchTimeStamp] = useState("");
  const [popup, setPopup] = useState(false);
  const [popupNew, setPopupNew] = useState(false);
  const [popupShowHistory, setPopupShowHistory] = useState(false);
  // const [timeFrame, setTimeFrame] = useState({
  //   start: format(subDays(new Date(Date.now()), 700), "yyyy-MM-dd"),
  //   end: format(subDays(new Date(), -31), "yyyy-MM-dd"),
  // });
  const [timeFrame, setTimeFrame] = useState({
    start: subDays(new Date(Date.now()), 700),
    end: subDays(new Date(), -31)
  });
  const [loading, setLoading] = useState(true);
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(LogiTarUser.current.vehicle ? (LogiTarUser.current.info.vehicles.find((row) => row.id == LogiTarUser.current.vehicle.id)): vehicles[0]);
  const [vehicleId, setVehicleId] = useState(null);
  //Count for handling grid update
  const [createNew, setCreateNew] = useState(false);
  const [selectedReport, setSelectedReport] = useState((() => {
      switch (userConfig.config.viewType) {
        case "both": return reportType[0];
        case "order": return reportType[1];
        case "history": return reportType[2];
        default: return reportType[1];
      }
    })());
  const [selectedReport1, setSelectedReport1] = useState(false);
  const [selectedReport2, setSelectedReport2] = useState(false);
  const [serviceHistoryRows, setServiceHistoryRows] = useState([]);
  const [showServiceOrder, setShowServiveOrder] = useState(false);
  const [showServiceHistory, setShowServiveHistory] = useState(false);
  const [autofuturTimeStamp, setAutofuturTimeStamp] = useState("");
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()
  const [historyData, setHistoryData] = useState();
  const [inProgressIcon, setInProgressIcon] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [historyFilters, setHistoryFilters] = useState({
    allservice: true,
    servicehistory: false,
    oilchange: false,
    interimservice: false,
    yearservice3: false,
  })
  const [filteredHistory, setFilteredHistory] = useState([])

  //Initialize selections
  useEffect(() => {
    switch (userConfig.config.viewType) {
      case "both":
        setSelectedReport1(true);
        setSelectedReport2(true);
        setShowServiveOrder(true);
        setShowServiveHistory(true);
        break;
      case "order":
        setSelectedReport1(true);
        setSelectedReport2(false);
        setShowServiveOrder(true);
        setShowServiveHistory(false);
        break;
      case "history":
        setSelectedReport1(false);
        setSelectedReport2(true);
        setShowServiveOrder(false);
        setShowServiveHistory(true);
        break;
      default:
        setShowServiveOrder(true);
        setSelectedReport1(true);
        break;
    }
  }, []);

  //Get vehicles
  useEffect(() => {
    if (LogiTarUser.current.info.userType === LogiTarUserType.DRIVER ) {
      const userid = LogiTarUser.current.info.id
      const vehicleid = LogiTarUser.current.vehicle.id 
      LogitarApi.getVehiclelist({userid: userid, method: "drivervehiclelist", vehicleid: vehicleid})
      .then((msg) => {
        setVehicles([{id:'', vehicle1:'-', licenseNumber:''}, ...msg.vehicles]);  

        if (LogiTarUser.current.vehicle ? LogiTarUser.current.vehicle.id : null) {
            msg.vehicles.forEach((value) => {
              if ( LogiTarUser.current.vehicle.id === value.id) {
                setSelectedVehicle(value);
              }
           })  
        }
      })      
      .catch((err) => {});
    } else {
      LogitarApi.getVehiclelist({userid: null, method: "vehiclelist"})
      .then((msg) => {
          setVehicles([{id:'', vehicle1:'-', licenseNumber:''}, ...msg.vehicles]);  
      })
      .catch((err) => {});
    }     
  }, []);

  useEffect(() => {
    setServiceOrder([]);
    setServiceOrderForEdit([]);
    setServiceHistory([]);
 
    const GetServiceHistory = async(search) => {
      try {
        // alert("GetServiceHistory " + searchTimeStamp + " " + autofuturTimeStamp)
        // alert("Valittu auto" + (selectedVehicle ? selectedVehicle.licenseNumber: null))
        // alert("Valittu id " + vehicleId)
        setInProgressIcon(true);

        let serviceHistory = []
        let serviceOrder = []
        let result = []
        
        result = await LogitarApi.getServiceHistoryV2({
          timeFrame: timeFrame,
          vehicle: vehicleId,
          method: search
        })

        // result.servicehistory.forEach((value) => { 
        //        alert("Auto " + value.licenseNumber);
        // })

        if (LogiTarUser.current.info.userType === LogiTarUserType.DRIVER ) {
          
          const selectedLicenseNumber = selectedVehicle.licenseNumber
          let driverServiceEvents = []

          //drivers one vehicle rows
          if (selectedLicenseNumber) {
           driverServiceEvents = result.servicehistory.filter((j) => j.licenseNumber === selectedLicenseNumber);

            // const userid = LogiTarUser.current.info.id
            // const vehicleid = LogiTarUser.current.vehicle.id 
            // LogitarApi.getVehiclelist({userid: userid, method: "drivervehiclelist", vehicleid: vehicleid})
            // .then((msg) => {
            //   let result2 = msg.vehicles
            //   driverServiceEvents = result.servicehistory.filter(history =>
            //     result2.some(vehicle => vehicle.licenseNumber === history.licenseNumber)
            //   );
            //   setServiceOrder([])
            //   setServiceOrder(driverServiceEvents);
            // })
          }
          //drivers all vehicles rows
          else {
            driverServiceEvents = result.servicehistory.filter(history =>
              vehicles.some(vehicle => vehicle.licenseNumber === history.licenseNumber)
            );
          }

          if (search == "serviceorder") {
            setServiceOrder(driverServiceEvents);
          } 
          else if (search == "servicehistory") {
            setServiceHistory(driverServiceEvents);
          } 
         }
        else {
          if (search == "serviceorder") {
            setServiceOrder(result.servicehistory);
            if (searchTimeStamp != "skip") {
              setAutofuturTimeStamp("refresh")
            }    
          } 
          else if (search == "servicehistory") {
            setServiceHistory(result.servicehistory);
          } 
        }
      }
      catch (err) {
        enqueueSnackbar("Huoltotapahtumien haku epäonnistui", { variant: 'error' });
      }
      finally {
        setInProgressIcon(false);
        setLoading(false)
      }
    } 

    if (selectedReport.type == 1) {
      GetServiceHistory("serviceorder") 
    }
    else if (selectedReport.type == 2) {
      GetServiceHistory("servicehistory") 
    }
    else if (selectedReport.type == 0) {
      GetServiceHistory("serviceorder") 
      GetServiceHistory("servicehistory") 
    }

  }, [searchTimeStamp])

  //Get Autofutur status using API
  useEffect(() => {
    const GetAutofutur = async() => {

      // alert("GetAutofutur " + searchTimeStamp + " " + autofuturTimeStamp)
      // alert("Valittu auto" + (selectedVehicle ? selectedVehicle.licenseNumber: null))
      setInProgressIcon(true);
      let workOrders= []
      let licenseNumbers= []
      let workOrder = ""
      let licenseNumber = ""

      if (showServiceOrder) {
        if (serviceOrder.length > 0) {
          serviceOrder.forEach((value) => { 
            if (value.workOrderNr && value.connection !== null) {
              workOrder = ({'workOrderNr':value.workOrderNr, 'connection':value.connection}); 
              workOrders.push(workOrder)
            } 
          })
        }
      }
      if (showServiceHistory) {
        if (serviceHistory.length > 0) {
          serviceHistory.forEach((value) => { 
            if (value.workOrderNr && value.connection !== null) {
              workOrder = ({'workOrderNr':value.workOrderNr, 'connection':value.connection}); 
              workOrders.push(workOrder)
            } 
          })
        }
      }

      let loadmethod =  "loadbyordernr"
      //let loadmethod =  "loadbyvehicle"

      if ((workOrders.length) > 0) {
        // alert(workOrders.length)
        // alert(licenseNumbers.length)    
        LogitarApi.loadAutofutur(licenseNumbers, workOrders, loadmethod).then((result) => {          
 
          setSearchTimeStamp("skip"); //trigger new GerServiceHistory call for updateting UI
          if (autofuturTimeStamp != "refresh") {
            enqueueSnackbar("Autofutur tiedot päivitetty")
          }
        }) 
        .catch((err) => {
          enqueueSnackbar("Autofutur haku epäonnistui", { variant: 'error' });
        })
        .finally(() => {
            setInProgressIcon(false);
        })    
      } else {
        setInProgressIcon(false);
      }
    }
    
    if (autofuturTimeStamp != "")  GetAutofutur() ; 
  }, [autofuturTimeStamp])

  useEffect(() => {
    if (loading) {
      return
    }
    let tempHistory = [...serviceHistory]
    tempHistory = serviceHistory.filter((value) => (
      (historyFilters["servicehistory"] && (value.workOrderNr || value.ExternalService_id))
      || (historyFilters["oilchange"] && value.type == 1)
      || (historyFilters["interimservice"] && value.type == 2)
      || (historyFilters["yearservice3"] && value.type == 3)
      || (historyFilters["allservice"])
    ))     
    setFilteredHistory(tempHistory)
  }, [serviceHistory, historyFilters])

  const vehicleSelected = (v) => {
    //  alert(v.id);
    console.log("Listalta valittu auto: ")
    console.log(v)

    setSelectedVehicle(v);
    setVehicleId(v.id);
    setSearchTimeStamp(Date.now());
  };

  const startTimeSelected = (timeFrame) => {
    setTimeFrame({ start: timeFrame.start, end: timeFrame.end });
    setSearchTimeStamp(Date.now());
  };

  const endTimeSelected = (timeFrame) => {
    setTimeFrame({ start: timeFrame.start, end: timeFrame.end });
    setSearchTimeStamp(Date.now());
  };

  const handleTimeFrame = (v, name) => {
    if (!v || isNaN(v.getTime())) {
        return
    }
    setTimeFrame(prev => ({ ...prev, [name]: v }))
    setSearchTimeStamp(Date.now());
  }

  const setNewSearchTimeStamp = () => {
    setSearchTimeStamp(Date.now());
  };

  const handlePopupNewService = () => {
    // setServiceOrderForEdit({
    //       vehicle: "", driver: "", orderDate: "",
    //       requestInfo: "", beforeKm: "", beforeDate: "",
    //       serviceDesc: "", serviceDoneKm: "", done: "",
    //       servicedBy: "", servicedByExt: "", inProgress: ""
    //  })

    setServiceOrderForEdit({
      vehicle: "",
      driver: "",
      driverByName: "",
      orderDate: "",
      servicedByExt: "",
      ExternalService_id: "",
      beforeDate: "",
      beforeKm: "",
      alarm: "",
      priority: "",
      connection: ""
    });
    setPopup(false);
    setPopupNew(true);
    setCreateNew(true);
  };

  const handlePopupShowHistoryDriver = (params) => {
    if (params.row.workOrderNr == null) {
      const method="serviceorder"
      LogitarApi.getServiceHistory({ id: params.row.id, method: method, extent: "allfile" })
        .then((result) => {
          if (result.status) {
            setServiceOrderForEdit(result.servicehistory[0]);
            setPopup(false);
            setPopupNew(true);
            setCreateNew(false);
            setPopupShowHistory(false)
            console.log("Fetched: " + result.servicehistory[0].id);
          }
        })
        .catch((err) => console.error(err));
    }
    else {
      setPopup(false);
      setPopupNew(false);
      setCreateNew(false);
      setPopupShowHistory(true)
   
      Object.keys(params.row).forEach((key) => {
        setHistoryData(prevData => ({
          ...prevData,
          [key]: params.row[key]
        }));
      })
    }
  };

  const handlePopupShowHistoryOffice = (params) => {
    setPopup(false);
    setPopupNew(false);
    setCreateNew(false);
    setPopupShowHistory(true);
 
    Object.keys(params.row).forEach((key) => {
      setHistoryData(prevData => ({
        ...prevData,
        [key]: params.row[key]
      }));
    })

    //alert("Historiassa " + params.row.vehicleByNumber)
    const method="servicehistory"
    LogitarApi.getServiceHistory({ id: params.row.id, method: method })
    .then((result) => {
      if (result.status) {
        // alert("haettu " + result.servicehistory[0].id)
        // alert("haettu " + result.servicehistory[0].serviceDesc)
        setServiceOrderForEdit(result.servicehistory[0]);
      }
    })
  };

  const  handlePopupUpdateService = (params) => {
    if (!params.row.id) {
      console.log("Can't open a empty serviceorder");
      return;
    }
    const method="serviceorder"
    LogitarApi.getServiceHistory({ id: params.row.id, method: method, extent: "allfile" })
      .then((result) => {
        if (result.status) {
          setServiceOrderForEdit(result.servicehistory[0]);
          setPopup(true);
          setPopupNew(false);
          setCreateNew(false);
          console.log("Fetched: " + result.servicehistory[0].id);
        }
      })
      .catch((err) => console.error(err));
  };

  const reportTypeSelected = (r) => {
    //alert(r.type);
    setSelectedReport(r);

    if (r.type === "0") {
      userConfig.config = { ...userConfig.config, viewType: "both" }
      setSelectedReport1(true);
      setSelectedReport2(true);
      setShowServiveOrder(true);
      setShowServiveHistory(true);
    } else if (r.type === "1") {
      userConfig.config = { ...userConfig.config, viewType: "order" }
      setSelectedReport1(true);
      setSelectedReport2(false);
      setShowServiveOrder(true);
      setShowServiveHistory(false);
    } else if (r.type === "2") {
      userConfig.config = { ...userConfig.config, viewType: "history" }
      setSelectedReport1(false);
      setSelectedReport2(true);
      setShowServiveOrder(false);
      setShowServiveHistory(true);
    }

    setHistoryFilters({
      allservice: true,
      servicehistory: false,
      oilchange: false,
      interimservice: false,
      yearservice3: false,
    })

    setSearchTimeStamp(Date.now());
  };

  const HistoryForm = () => {
    return (
      <Box style={{ width: 600, maxWidth: 600 }}>
        {columnsServiceHistory.map((row, key) => {
            if (row.field == "requestInfo" || row.field == "serviceDesc") {
              return (
                <Box key={key} sx={{ ...dialogTextStyle, mt:1}}>
                  <span style={{flex: 1 }}>{row.headerName}:</span> 
                  <span style={{flex: 3 }}>
                  <TextField sx={{"& fieldset": { border: 'none'}, width: '100%'}} multiline value =  {historyData[row.field]}></TextField>
                  </span> 
                </Box>
              )
            }
            else {
              return(
                <Box key={key} sx={{ ...dialogTextStyle, mt:1}}>
                  <span style={{flex: 1 }}>{row.headerName}:</span> 
                  <span style={{ marginLeft: 25, textAlign: "left", flex: 3, overflowWrap: "anywhere" }}>
                  {row?.valueFormatter != null ? row.valueFormatter({ value: historyData[row.field] }) : historyData[row.field]}
                  </span>
                </Box>
              )
            }
        })}
        <br></br>
      </Box>
    )
  };

   //Remove whole order
   const handleRemove = () => {    
      // alert(historyData.uuid)
      // alert(historyData.id)
      let serviceHistoryOrder = ({'uuid':historyData.uuid, 'orderid':historyData.id}); 
      LogitarApi.setServiceHistoryRow(serviceHistoryOrder, false, true, false).then((result) => {
        LogitarApi.setServiceHistory(serviceHistoryOrder, true).then((result) => {
          enqueueSnackbar("Huoltotilaus poistettu")   
          setDialog(false)
          setPopupShowHistory(false)
          setSearchTimeStamp(Date.now());            
        })
      })
  }

  //Save orderhistory
  const handleChangeOrder = (event) => {    
    let servicehistoryorder = '';
    servicehistoryorder = ({...serviceOrderForEdit, 'serviceDesc': historyData['serviceDesc']}); 
    LogitarApi.setServiceHistory(servicehistoryorder, false).then((result) => {
      console.log("Huoltohistoria tallennettu ")
      console.log(serviceOrderForEdit)
      enqueueSnackbar("Huoltohistoria tallennettu")
      setSearchTimeStamp(Date.now());      
      setPopupShowHistory(false);
    }).catch((e) => {
      console.error(e);
      enqueueSnackbar("Huoltohistorian tallennus epäonnistui", { variant: "error" });
    })
  }

  //Change order
  const handleModifyOrder = (event) => {
    event.preventDefault();
    setHistoryData({...historyData, [event.target.name]: event.target.value})
    setServiceOrderForEdit({...serviceOrderForEdit,  [event.target.name]: event.target.value} )    
  }

  const handleOptions = (e) => {
    if (e.target.type === "checkbox") {
        setHistoryFilters(prev => ({ ...prev, [e.target.name]: !prev[e.target.name] }))
    } 

    if (e.target.checked) {
      if ([e.target.name] == 'allservice'){
          setHistoryFilters(prev => ({ ...prev,  servicehistory: false }))
          setHistoryFilters(prev => ({ ...prev,  oilchange: false }))
          setHistoryFilters(prev => ({ ...prev,  interimservice: false }))
          setHistoryFilters(prev => ({ ...prev,  yearservice3: false }))
      }
      if ([e.target.name] == 'servicehistory' || [e.target.name] == 'oilchange' || [e.target.name] == 'interimservice' || [e.target.name] == 'yearservice3'){
        setHistoryFilters(prev => ({ ...prev,  allservice: false }))
      }
    }
  }

  return (

    // <Box sx={{ display: 'flex', flexDirection: 'column', overflowY: 'auto', height: '100%' }} className={"service-history"} >
    <Box
      sx={{
        ...contentBoxStyle,
        display: "flex",
        flexDirection: "column",
         maxHeight: '85vh',

      }}
    >

      <Stack
        spacing={{ xs: 1, sm: 2 }}
        direction="row"
        useFlexGap
        flexWrap="wrap"
      >

        <LocalizationProvider adapterLocale={fi} dateAdapter={AdapterDateFns}>
            <DesktopDatePicker              
                label="Tilauksen alkupäivä"
                inputFormat='dd.MM.yyyy'
                mask={"__.__.____"}
                value={timeFrame.start}
                onChange={(value) => handleTimeFrame(value, "start")}             
                timeFrameSelected
                renderInput={(params) => <TextField size="small" sx={{ width: 120,  heigth: 30}} {...params} />}
            ></DesktopDatePicker>
        </LocalizationProvider>
        <LocalizationProvider adapterLocale={fi} dateAdapter={AdapterDateFns}>
            <DesktopDatePicker 
                label="Tilauksen loppupäivä"
                inputFormat='dd.MM.yyyy'
                mask={"__.__.____"}
                value={timeFrame.end}
                onChange={(value) => handleTimeFrame(value, "end")}
                renderInput={(params) => <TextField size="small" sx={{ width: 120,  heigth: 30}} {...params} />}
            ></DesktopDatePicker>
        </LocalizationProvider>

        <LocalizationProvider adapterLocale={fi} dateAdapter={AdapterDateFns}>
          <FormControl>
            <InputLabel id="service-vehicle-label" sx={{ fontSize: 16 }} shrink>
              Auto
            </InputLabel>
            <Select
              labelId="service-vehicle-label"
              label="Auto"
              value={selectedVehicle || ''}
              //onChange={(e) => setSelectedVehicle(e.target.value)}
              onChange={(e) => vehicleSelected(e.target.value)}
              sx={{ width: 200, heigth: 30}}
              notched
            >
              {/* <MenuItem value={false}>-</MenuItem> */}
              {[...vehicles].map((value, key) => {
                return (
                  <MenuItem value={value} key={key}>
                    {/* {value.id}:{value.licenseNumber} */}
                    {value.vehicle1}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </LocalizationProvider>

        <LocalizationProvider adapterLocale={fi} dateAdapter={AdapterDateFns}>
          <FormControl>
            <InputLabel id="service-report-type-label" sx={{ fontSize: 16 }}>
              Raportti
            </InputLabel>
            <Select
              labelId="service-report-type-label"
              label="Raportti"
              value={selectedReport}
              onChange={(e) => reportTypeSelected(e.target.value)}
              sx={{ width: 250, heigth: 30}}
            >
              {/* <MenuItem value={0}>-</MenuItem> */}
              {[...reportType].map((value, key) => {
                return (
                  <MenuItem value={value} key={key}>
                    {value.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </LocalizationProvider>

        {/* Autofutur search */}
        {(showServiceOrder || showServiceHistory) &&
          <TipButton variant="contained" icon={Sync} 
              sx={{ ml:2, mt:1}}                 
              title="Haku Autofuturesta" 
              style={{ height: '30px' }}
              disabled={(!selectedVehicle && showServiceHistory) || (selectedVehicle && selectedVehicle.licenseNumber.length == 0 && showServiceHistory)}
              onClick={() => setAutofuturTimeStamp(Date.now(), setSearchTimeStamp(Date.now()))} 
          />
        }

        {/* CheckBoxes only for history, not orders */}
        {(!showServiceOrder && showServiceHistory) && (
          <Box sx={{ ...checkersStyle, mb: 0, ml: 10}}>
              {Object.keys(historyFilters).map((filter, key) => (
                  <Tooltip key={key} title={filterLabels[filter].tooltip}>
                      <FormControlLabel
                          sx={{ ...checkboxWithLabelStyle }}
                          className={filterLabels[filter].className}
                          control={<Checkbox
                              name={filter}
                              onChange={handleOptions}
                              value={historyFilters[filter]}
                              checked={historyFilters[filter]}
                              sx={{ color: "text.primary", "&.Mui-checked": { color: 'text.primary' } }}
                          />}
                          label={filterLabels[filter].label}
                      />
                  </Tooltip>
              ))}
          </Box>
        )}

        {inProgressIcon &&
        <CircularProgress size={20} sx={{ ml:4, mt:1}}     />
        }

      </Stack>

      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%',  maxHeight: '85vh', }} className={"service-history" } >
        {showServiceOrder && (
          <div>
            <Typography sx={{ p: 2 }}>Huoltotilaukset</Typography>
            <PersistentDataGridPro
              userConfig={userConfigOrder}
              rows={serviceOrder}
              columns={columnsServiceOrder}
              pageSize={20}
              rowsPerPageOptions={[20]}
              disableSelectionOnClick={true}
              sx={{maxHeight: '82vh'}} // Adjusted to avoid scrollbar
              onRowClick={(params) => {LogiTarUser.current.isDriver() ? handlePopupShowHistoryDriver(params) : handlePopupUpdateService(params)}}
              density='compact'

              components={{
                Toolbar: () => (
                  <CustomToolbar
                    userConfig={userConfigOrder}
                    text={"Uusi vikatilaus"}
                    onClick={handlePopupNewService}
                    hidePrinting
                  ></CustomToolbar>
                ),
              }}
              localeText={localeText}
              getRowClassName={(params) => {
                if (params.row.done === null || params.row.done === undefined) {
                    if (params.row.workOrderNr > 0) {
                      return "autofutur-sent";
                    }
                    return "autofutur-resend";
                }
                else {
                  return "autofutur-normal";
                }
              }}
              slotProps={toolbarSlotProps}
            />
          </div>
        )}

        {showServiceHistory && (
          <div>
            <Typography sx={{ p: 2 }}>Huolto- ja katsatushistoria</Typography>
            <PersistentDataGridPro
              userConfig={userConfigHistory}
              rows={filteredHistory}
              columns={columnsServiceHistory}
              pageSize={20}
              rowsPerPageOptions={[20]}
              disableSelectionOnClick={true}
              sx={{maxHeight: '73.5vh'}} // Adjusted to avoid scrollbar
              onRowClick={(params) => {handlePopupShowHistoryOffice(params)}}
              density='compact'
              localeText={localeText}
              getRowClassName={(params) => {
                if (params.row.done !== null && params.row.done !== undefined) {
                    if ((params.row.workOrderNr > 0 || params.row.ExternalService_id > 0 )) {
                      return "row-default-grey";
                    }
                    if (params.row.type === 1) {
                      return "row-default-blue";
                    }
                    if (params.row.type === 2) {
                      return "row-default-yellow";
                    }
                    if (params.row.type === 3) {
                      return "row-default-green";
                    }
                }
              }}
              slots={{
                toolbar: () => <CustomToolbar userConfig={userConfigHistory} hideButton hidePrinting
                  customElement={<ColorInfo colors={[
                    { class: "row-default-grey", info: "Huoltotapahtuma" },
                    { class: "row-default-blue", info: "Öljynvaihto" },
                    { class: "row-default-yellow", info: "Välihuolto" },
                    { class: "row-default-green", info: "3v huolto" },
                  ]} sx={{ ml: "auto", mr: 2 }} />} />
              }}
            />
          </div>
        )}

        {popup && (
          <div>
              <ServiceOrderEdit
                serviceHistory={serviceOrderForEdit}
                vehicles={vehicles}
                onClick={setPopup}
                new={createNew}
                rowUpdate={setNewSearchTimeStamp}
              ></ServiceOrderEdit>
          </div>
        )}

        {popupNew  && (
          <div>
              <NewServiceOrder
                serviceHistory={serviceOrderForEdit}
                vehicles={vehicles}
                onClick={setPopupNew}
                new={createNew}
                rowUpdate={setNewSearchTimeStamp}
              ></NewServiceOrder>
          </div>
        )}

        {/* Office history. Can update orderhistory */}
        {popupShowHistory && (LogiTarUser.current.info.userType != LogiTarUserType.DRIVER) && (historyData.done2) && (     
          <PopupScreen
          draggable
          title={"Huoltohistoria"}
          onClose={() => {setPopupShowHistory(false);}}
          onSave={() => {handleChangeOrder();}}
          >
              <Box style={{ width: 600, maxWidth: 600 }}>
              {columnsServiceHistory.map((row, key) => {
                  if (row.field == "requestInfo" ) {
                    return (
                      <Box key={key} sx={{ ...dialogTextStyle, mt:1}}>
                        <span style={{flex: 1 }}>{row.headerName}:</span> 
                        <span style={{flex: 3 }}>
                        <TextField sx={{"& fieldset": { border: 'none'}, width: '100%'}} multiline value =  {historyData[row.field]}></TextField>
                        </span> 
                      </Box>
                    )
                  }
                  else if (row.field == "serviceDesc") {
                    return (
                        <Box key={key} sx={{ ...dialogTextStyle, mt:1}}>
                          <span style={{flex: 1 }}>{row.headerName}:</span> 
                          <span style={{flex: 3 }}>
                          <TextField name = {row.field}  onChange={handleModifyOrder} sx={{width: '100%'}} multiline value = {historyData[row.field]} ></TextField>
                          </span> 
                        </Box>
                      )
                  } else if (
                    (LogiTarUser.current.info.userType === LogiTarUserType.MANAGEMENT
                    || LogiTarUser.current.info.userType === LogiTarUserType.ADMIN)
                    && row.field === "serviceDoneKm") {
                    return (
                      <Box key={key} sx={{ ...dialogTextStyle, mt: 1 }}>
                        <span style={{ flex: 1 }}>{row.headerName}:</span> 
                        <span style={{ flex: 3 }}>
                          <TextField
                            name={row.field}
                            onChange={handleModifyOrder}
                            sx={{ width: '50%' }}
                            value={historyData[row.field] || ""}
                            size="small"
                            error={historyData[row.field] ? isNaN(historyData[row.field]) : false}
                            helperText={(historyData[row.field] && isNaN(historyData[row.field])) ? "Arvon täytyy olla numero" : undefined}
                          />
                        </span> 
                      </Box>
                    )
                  } else if (
                    (LogiTarUser.current.info.userType === LogiTarUserType.MANAGEMENT
                    || LogiTarUser.current.info.userType === LogiTarUserType.ADMIN)
                    && row.field === "done") {
                    return (
                      <Box key={key} sx={{ ...dialogTextStyle, mt: 1 }}>
                        <span style={{ flex: 1 }}>{row.headerName}:</span> 
                        <span style={{ flex: 3 }}>
                          <DatePick
                            date={historyData[row.field] != null ? new Date(historyData[row.field]) : null}
                            dateSet={(value) => {
                              if (!value || isNaN(value.getTime())) return;
                              setHistoryData(prev => ({ ...prev, [row.field]: format(value, "yyyy-MM-dd") }));
                              setServiceOrderForEdit(prev => ({ ...prev, [row.field]: format(value, "yyyy-MM-dd") }));
                            }}
                            sx={{ width: "50%" }}
                            height={40}
                          />
                        </span> 
                      </Box>
                    )
                  } else {
                    return(
                      <Box key={key} sx={{ ...dialogTextStyle, mt:1}}>
                        <span style={{flex: 1 }}>{row.headerName}:</span> 
                        <span style={{ marginLeft: 25, textAlign: "left", flex: 3, overflowWrap: "anywhere" }}>
                        {row?.valueFormatter != null ? row.valueFormatter({ value: historyData[row.field] }) : historyData[row.field]}
                        </span>
                      </Box>
                    )
                  }
              }
              )}
              <br></br>
              </Box>
          </PopupScreen>
        )}

        {/* Drivers history. Not allow update nor delete. */}
        {popupShowHistory && (LogiTarUser.current.info.userType === LogiTarUserType.DRIVER) && (historyData.done2) && (     
          <PopupScreen
          draggable
          title={"Huoltohistoria"}
          onClose={() => {setPopupShowHistory(false);}}
          >
              <HistoryForm/>
          </PopupScreen>
        )}

        {/* Drivers order. Can remove order if not sent Autofutur (workOrderNr) */}
        {popupShowHistory && (LogiTarUser.current.info.userType === LogiTarUserType.DRIVER) && (historyData.done2 == null) && (historyData.workOrderNr == null) && (     
          <PopupScreen
          draggable
          title={"Huoltotapahtuma"}
          onClose={() => {setPopupShowHistory(false);}}
          onDelete={() => {setDialog(true);}}
          staticButtons
          >
              <HistoryForm/>
          </PopupScreen>
        )}

        {/* Drivers order. Can not remove order if sent Autofutur (workOrderNr) */}
        {popupShowHistory && (LogiTarUser.current.info.userType === LogiTarUserType.DRIVER) && (historyData.done2 == null) && (historyData.workOrderNr != null) && (     
          <PopupScreen
          draggable
          title={"Huoltotapahtuma"}
          onClose={() => {setPopupShowHistory(false);}}
          staticButtons
          >
              <HistoryForm/>
          </PopupScreen>
        )}

        <Dialog open={dialog} onClose={() => setDialog(false)}>
        <Box sx={exitContainerStyle}>
            <DialogTitle sx={{ pl: 2 }}>Huoltotapahtuman poisto</DialogTitle>
            <Close sx={{ ...exitIconStyle }} variant="contained"
                onClick={() => { setDialog(false) }}>
                Poistu
            </Close>
        </Box>
        <DialogTitle>
            {"Haluatko varmasti poistaa valitun huoltotapahtuman?"}
        </DialogTitle>
        <DialogActions>
            <Button  onClick={() => {handleRemove(); setDialog(false)}} autoFocus>Kyllä</Button>
        </DialogActions>
        </Dialog>

      </Box>
    </Box>
  );
}
